import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import LocalizedFormats from 'dayjs/plugin/localizedFormat';
dayjs.extend(LocalizedFormats);
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);


export {};
