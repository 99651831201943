import { decode, encode } from 'base-64';

//https://stackoverflow.com/questions/42829838/react-native-atob-btoa-not-working-without-remote-js-debugging
if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

// Polyfill neeed until we upgrade to 0.64
Promise.allSettled = Promise.allSettled || ((promises: any[]) => Promise.all(
  promises.map(p => p
    .then((value: any) => ({
      status: 'fulfilled',
      value,
    }))
    .catch((reason: any) => ({
      status: 'rejected',
      reason,
    })),
  ),
));

export {};
